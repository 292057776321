import { cn } from "@jammable/ui-core";
import { YoutubeInput, inputStyle } from "../Input";
import { useDropzone } from "react-dropzone";
import { useEffect } from "react";

export interface AudioInputProps {
  containerClassname?: string;
  orClassname?: string;
  ytClassname?: string;
  inputClassname?: string;
  uploadText?: string;
  onChange: (source: string | File) => void;
}

export function AudioInput({
  onChange,
  containerClassname,
  inputClassname,
  orClassname,
  ytClassname,
  uploadText = "upload an audio file...",
}: AudioInputProps) {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "audio/*": [".mp3", ".wav", ".m4a"],
    },
    maxSize: 50 * 1024 * 1024, // 50MB
  });

  useEffect(() => {
    onChange(acceptedFiles[0]);
  }, [onChange, acceptedFiles]);

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div
      className={cn(
        "tw-w-full tw-items-center tw-gap-4 tw-py-6 tw-text-center md:tw-flex",
        containerClassname,
      )}
    >
      <YoutubeInput
        className={cn("tw-m-auto tw-w-full sm:tw-w-1/2", ytClassname)}
        onChange={handleLinkChange}
        data-testid="input-youtube-url"
      />
      <div className={cn("tw-font-bold", orClassname)}>or</div>
      <div
        {...getRootProps({
          className: cn(
            inputStyle,
            "tw-m-auto tw-flex tw-w-full tw-flex-1 tw-cursor-pointer tw-flex-col tw-items-center tw-border-2 tw-border-dashed tw-border-purple-700 tw-bg-white tw-text-purple-700 tw-outline-none sm:tw-w-1/2",
            { "tw-border-green-500": isDragAccept },
            { "tw-border-red-500": isDragReject },
            inputClassname,
          ),
        })}
      >
        <input {...getInputProps()} />

        <p
          className={cn(
            "tw-my-auto tw-block tw-truncate tw-text-sm tw-font-bold",
            inputClassname,
          )}
        >
          {acceptedFiles?.[0]?.name || uploadText}
        </p>
      </div>
    </div>
  );
}
