import axios from "axios";
import Link from "next/link";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  UseInfiniteQueryResult,
  useInfiniteQuery,
  useQuery,
} from "react-query";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { NextSeo } from "next-seo";
import { IoFlame } from "react-icons/io5";
import VoiceImage from "../components/VoiceImage";
import { useModal } from "@/context/ModalContext";
import debounce from "lodash/debounce";
import ProfileSection from "../components/home/ProfileSection";
import HomePageModelCard from "../components/home/ModelCard";
import { CreateYourOwnCTA } from "@jammable/ui-homepage/src/components/CreateYourOwnCTA";
import DynamicSection from "../components/home/DynamicSection";
import Subtitle from "../components/home/Subtitle";
import { HiCollection } from "react-icons/hi";
import { getAuth } from "firebase/auth";
import { useAuth } from "@/context/AuthProvider";
import CollectionCard from "../components/home/CollectionCard";
import { FaRedo, FaSearch } from "react-icons/fa";
import ComboCard from "../components/home/ComboCard";
import { PiMicrophoneStageFill } from "react-icons/pi";
import Image from "next/image";
import { cn, modelSearchPageUrl, SecondaryButton } from "@jammable/ui-core";
import { ArrowRight, ChevronRight } from "lucide-react";
import { getUrlForSrc } from "@jammable/ui-components/Media";
import bannerImg from "@jammable/ui-components/Media/backgrounds/banner.png";
import { Input } from "@jammable/ui-components/Forms";

const sortOptions = {
  hot: "usesLast24Hours",
  top: "uses",
  new: "createdAt",
};

interface Trending {
  isTrending: boolean;
  position: number | undefined;
}

interface Info {
  trainedBy: string;
  uploadedBy: string;
  pitchShift: number | null;
}

export interface ApiListResponse<T> {
  data: T[];
  paging: {
    hasMore: boolean;
  };
  collections?: any[];
}

export interface Model {
  _id: string;
  artistname: string;
  uses: number;
  user: {
    username: string;
  };
  image: {
    url: string;
    blurhash?: string;
  };
  isLive: boolean;
  isPublic: boolean;
  isCommunity: boolean;
  urlParam: string;
  badges?: string[];
  accessIds: string[];
  info: Info;
  likes?: number;
  trending: Trending;
  description?: string;
  createdAt: Date;
  updatedAt: Date;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any

const HomePage = () => {
  const router = useRouter();
  const { query } = router;
  const { t } = useTranslation();

  const { data: trendingVoices, isLoading: isTrendingLoading } = useQuery(
    "trending-voices",
    () => axios.get("/api/home/getTrendingVoices"),
  );

  const { data: topCollections, isLoading: isTopCollectionsLoading } = useQuery(
    "top-collections",
    () => axios.get("/api/home/getTopCollections"),
  );

  const { data: topCombos, isLoading: isTopCombosLoading } = useQuery(
    "top-combos",
    () => axios.get("/api/home/getTopCombos"),
  );

  const { user } = useAuth();
  const { data: recentVoices, isLoading: isRecentVoicesLoading } = useQuery(
    ["recent-voices", user?.uid],
    async () => {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();
      return axios.get("/api/home/getRecentVoices", {
        headers: {
          Authorization: token,
        },
      });
    },
    { enabled: !!user },
  );

  const { data: collections } = useQuery("collections", () =>
    axios.get("/api/home/getCollections"),
  );

  const ModelSkeletonLoader = () => {
    return (
      <>
        {[...Array(6)].map((_, index) => (
          <div
            key={index}
            className="tw-animate-pulse tw-h-[120px] tw-w-80 tw-min-w-80 tw-w-full tw-bg-gray-100 tw-p-3 tw-rounded-3xl tw-flex tw-items-center"
          />
        ))}
      </>
    );
  };

  const CollectionSkeletonLoader = () => {
    return (
      <>
        {[...Array(6)].map((_, index) => (
          <div
            key={index}
            className="tw-animate-pulse tw-h-32 tw-w-60 tw-min-w-60 tw-w-full tw-bg-gray-100 tw-p-3 tw-rounded-4xl tw-flex tw-items-center"
          />
        ))}
      </>
    );
  };

  const VoiceGrid = ({
    children,
    height,
  }: {
    children: React.ReactNode;
    height?: string;
  }) => {
    return (
      <div
        className={cn(
          "tw-flex tw-w-full tw-gap-2 tw-overflow-x-scroll tw-overflow-y-visible tw-scrollbar-h-1 tw-scrollbar-w-0 tw-scrollbar-thin tw-scrollbar-thumb-transparent tw-pb-0",
          height ? `tw-h-[${height}]` : "tw-h-[130px]",
        )}
      >
        {children}
      </div>
    );
  };

  const memoizedTrendingVoices = useMemo(
    () => (
      <VoiceGrid>
        {isTrendingLoading ? (
          <ModelSkeletonLoader />
        ) : (
          trendingVoices?.data?.models?.map((model: Model, index: number) => (
            <HomePageModelCard
              model={model}
              index={index}
              key={model._id}
              animate={false}
              grid={false}
            />
          ))
        )}
      </VoiceGrid>
    ),
    [trendingVoices, isTrendingLoading],
  );

  const memoizedTopCollections = useMemo(
    () => (
      <VoiceGrid height="null">
        {isTopCollectionsLoading ? (
          <CollectionSkeletonLoader />
        ) : (
          topCollections?.data?.collections?.map((collection: any) => (
            <CollectionCard collection={collection} key={collection._id} />
          ))
        )}
      </VoiceGrid>
    ),
    [topCollections, isTopCollectionsLoading],
  );

  const memoizedRecentVoices = useMemo(
    () => (
      <VoiceGrid height="null">
        {isRecentVoicesLoading ? (
          <ModelSkeletonLoader />
        ) : (
          recentVoices?.data?.voices?.map((model: Model) => (
            <HomePageModelCard
              model={model}
              key={model._id}
              grid={false}
              animate={false}
              index={0}
            />
          ))
        )}
      </VoiceGrid>
    ),
    [recentVoices, isRecentVoicesLoading],
  );

  const memoizedTopCombos = useMemo(
    () => (
      <VoiceGrid height="null">
        {isTopCombosLoading ? (
          <CollectionSkeletonLoader />
        ) : (
          topCombos?.data?.models?.map((combo: any) => (
            <ComboCard combo={combo} key={combo._id} />
          ))
        )}
      </VoiceGrid>
    ),
    [topCombos, isTopCombosLoading],
  );

  const memoizedCollections = useMemo(
    () => (
      <div className="tw-w-full">
        {collections?.data?.collections?.map((collection: any) => (
          <div key={collection._id}>
            <Subtitle
              title={collection.title}
              subtitle={`Collection by @${collection.username}`}
              icon={
                <Image
                  src={collection.image.url}
                  alt={collection.title}
                  width={32}
                  height={32}
                  className="tw-rounded-full"
                />
              }
              iconBgColor="tw-bg-lime-300"
              iconColor="tw-text-white"
              className=""
              cta={
                <SecondaryButton
                  className=" tw-text-xs tw-ml-auto tw-uppercase tw-text-black/40 tw-tracking-widest tw-font-black   tw-rounded-full tw-h-10 md:tw-px-4 tw-border-none tw-flex tw-items-center tw-gap-1"
                  href={`/collection/${collection._id}`}
                >
                  <span>COLLECTION</span>
                  <ChevronRight size={18} strokeWidth={3} />
                </SecondaryButton>
              }
            />
            <div className="tw-flex tw-w-full tw-gap-2  tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-pb-2 ">
              {collection.modelDetails.map((model: any) => (
                <HomePageModelCard
                  model={model}
                  key={model._id}
                  grid={true}
                  animate={false}
                  index={0}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    ),
    [collections],
  );

  return (
    <div className="container">
      <NextSeo
        title={"Jammable | Create AI Covers with your Favorite Voices!"}
        description={
          "Create AI covers in seconds with Jammable, with thousands of community uploaded AI voice models available for creative use now!"
        }
        themeColor="#782DC8"
        openGraph={{
          title: `Jammable | Create AI Covers with Your Favorite Voices | AI-Powered Music Creation`,
          description:
            "Create AI covers in seconds with Jammable, with thousands of community uploaded AI voice models available for creative use now!",
          type: "website",
          locale: "en_IE",
          siteName: "Jammable",
        }}
        twitter={{
          handle: "@handle",
          site: "@site",
          cardType: "summary_large_image",
        }}
      />{" "}
      <div className="md:tw-mt-6 tw-mt-2 tw-max-w-[1420px] tw-w-full tw-flex tw-h-full tw-flex-col tw-items-start tw-justify-start  tw-px-4">
        <div className="tw-w-full md:tw-mb-4 tw-flex md:tw-flex-row tw-flex-col tw-justify-between tw-gap-4">
          {false ? (
            <ProfileSection />
          ) : (
            <>
              <div className="tw-h-auto tw-flex tw-flex-col tw-justify-center">
                <h1
                  style={{
                    backgroundImage: `url(${getUrlForSrc(bannerImg)})`,
                    backgroundColor: "black",
                  }}
                  className={cn(
                    `tw-m-0 tw-stroke-slate-50 tw-mb-0 tw-pt-0 tw-pb-0 tw-text-4xl tw-font-black tw-tracking-tighter sm:tw-text-6xl`,
                    `tw-bg-right-bottom [background-clip:text] [color:transparent]`,
                  )}
                >
                  Create AI Covers with your Favorite Voices
                </h1>
                <p className=" tw-my-1 tw-mb-1 tw-text-lg md:tw-text-xl tw-font-bold tw-text-black/40 tw-uppercse ">
                  The #1 platform for making high quality AI covers in seconds!
                </p>
                <div className="tw-flex tw-w-full tw-max-w-2xl tw-mt-1">
                  <Input
                    placeholder="Search all voices..."
                    icon={<FaSearch size={20} />}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const searchTerm = (e.target as HTMLInputElement).value;
                        window.location.href = modelSearchPageUrl(searchTerm);
                      }
                    }}
                    className=" tw-w-full tw-justify-center tw-h-14 tw-rounded-2xl"
                    inputClassName=" tw-rounded-l-2xl tw-pl-4 tw-text-gray-700"
                  />
                </div>
              </div>
            </>
          )}
          <DynamicSection mobile={false} />
        </div>

        <Subtitle
          title="Trending Now"
          subtitle="Check out today's top voices!"
          icon={<IoFlame size={20} />}
          iconBgColor="tw-bg-orange-400"
          iconColor="tw-text-white"
          cta={null}
          className="tw-mt-0"
        />

        {memoizedTrendingVoices}

        <Subtitle
          title="Top Collections"
          subtitle="Check out today's top collections!"
          icon={<HiCollection size={22} />}
          iconBgColor="tw-bg-lime-300"
          iconColor="tw-text-white"
          cta={null}
          className=""
        />
        {memoizedTopCollections}
        {(!!user && recentVoices?.data?.voices?.length > 0) ||
        isRecentVoicesLoading ? (
          <Subtitle
            title="Jump back in"
            subtitle="Check out your recently used voices!"
            icon={<FaRedo size={12} />}
            iconBgColor="tw-bg-gray-400"
            iconColor="tw-text-white"
            cta={null}
            className=""
          />
        ) : null}
        {memoizedRecentVoices}

        <DynamicSection mobile={true} />
        <Subtitle
          title="Trending Collabs"
          subtitle="Check out today's top collabs!"
          icon={<PiMicrophoneStageFill size={14} />}
          iconBgColor="tw-bg-purple-400"
          iconColor="tw-text-white"
          cta={null}
          className=""
        />

        {memoizedTopCombos}

        {memoizedCollections}
      </div>
    </div>
  );
};

export default HomePage;
