import React from "react";

const Subtitle = ({
  title,
  subtitle,
  icon,
  iconColor,
  iconBgColor,
  cta,
  className,
}) => {
  return (
    <div
      className={`tw-text-xl tw-text-nowrap tw-truncate tw-w-full tw-font-bold tw-mb-4 tw-mt-3 tw-flex tw-items-center tw-gap-2 ${className}`}
    >
      <div
        className={`tw-bg-black tw-text-white tw-p-2 tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-aspect-square tw-text-sm ${iconColor} ${iconBgColor}`}
      >
        {icon}
      </div>
      <div className="tw-truncate">
        {title}
        <div className="tw-text-xs tw-text-nowrap tw-truncate tw-w-full tw-text-black/40 tw-font-semibold">
          {subtitle}
        </div>
      </div>
      {cta && cta}
    </div>
  );
};

export default Subtitle;
