import React from "react";
import VoiceImage from "../VoiceImage";
import Link from "next/link";

const ComboCard = ({ combo }) => {
  let artistNames;
  if (combo?.models && combo.models.length > 0) {
    const names = combo.models.map((model) => model.artistname);
    if (names.length > 1) {
      artistNames =
        names.slice(0, -1).join(", ") + " & " + names[names.length - 1];
    } else {
      artistNames = names[0];
    }
  }
  return (
    <Link
      href={{
        pathname: "/duets",
        query: { voices: combo.models.map((model) => model._id).join(",") },
      }}
      className="tw-flex tw-w-60 tw-min-w-60 tw-flex-col tw-items-center tw-gap-1 tw-p-2 tw-bg-gray-100 tw-rounded-3xl hover:tw-bg-gray-200/70 tw-transition-all tw-cursor-pointer"
    >
      <div className="tw-text-[11px] tw-tracking-widest tw-mb-1 tw-uppercase tw-font-black tw-text-black/40 tw-text-center tw-bg-gray-200 tw-p-0.5 tw-px-2 tw-rounded-full">
        {combo.count} uses today
      </div>
      <div className="tw-flex tw-flex-row">
        {combo?.models?.map((model, index) => (
          <VoiceImage
            style={{
              marginLeft: index !== 0 ? -15 : "0",
            }}
            className="tw-rounded-full tw-border-3 tw-border-gray-100 tw-border-solid"
            model={model}
            size={50}
            key={model._id}
          />
        ))}
      </div>
      <div className="tw-text-xs tw-font-bold tw-text-black/40 tw-text-center tw-line-clamp-2 tw-w-full">
        {artistNames}
      </div>
    </Link>
  );
};

export default ComboCard;
