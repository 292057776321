import { useAuth } from "@/context/AuthProvider";
import glitch from "./assets/glitch.png";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import { IoFlame } from "react-icons/io5";
import { Button, SecondaryButton } from "@jammable/ui-core";
import { PiMicrophoneStageFill } from "react-icons/pi";
import Link from "next/link";
import { FaHeart } from "react-icons/fa";

const ProfileSection = () => {
  const [greeting, setGreeting] = useState("");
  const [secondaryGreeting, setSecondaryGreeting] = useState("");
  useEffect(() => {
    const getGreeting = () => {
      const currentHour = new Date().getHours();
      const greetings = [
        ["Hey, early riser", "Morning", "Sup", "Hey there", "What's up"],
        ["Yo", "Hey", "Hi there", "What's good", "Howdy"],
        ["Evening", "Hey you", "What's happening", "Hi", "Hiya"],
        [
          "Still up?",
          "Night owl, huh?",
          "Yo, night person",
          "Can't sleep?",
          "Late night vibes",
        ],
      ];

      let timeIndex;
      if (currentHour >= 5 && currentHour < 12) timeIndex = 0;
      else if (currentHour >= 12 && currentHour < 18) timeIndex = 1;
      else if (currentHour >= 18 && currentHour < 21) timeIndex = 2;
      else timeIndex = 3;

      const randomIndex = Math.floor(
        Math.random() * greetings[timeIndex].length,
      );
      return greetings[timeIndex][randomIndex];
    };
    const getSecondaryGreeting = () => {
      const secondaryGreetings = [
        "What will you create today?",
        "Time to get creative!",
        "Unleash your creativity!",
        "Create something amazing!",
        "Let's make magic happen!",
      ];
      const randomIndex = Math.floor(Math.random() * secondaryGreetings.length);
      return secondaryGreetings[randomIndex];
    };

    setSecondaryGreeting(getSecondaryGreeting()); // Set secondary greeting

    setGreeting(getGreeting());
  }, []);
  const { user } = useAuth();
  const getFilledSegments = (streak) => {
    return streak % 5;
  };

  const currentStreak = user?.streaks?.currentStreak + 1 ?? 0;
  return (
    <div
      className="tw-bg-gray-50  tw-shadow tw-p-4 tw-w-full tw-rounded-4xl  tw-text-black tw-h-max tw-relative tw-flex md:tw-flex-row tw-flex-col  tw-gap-2 tw-justify-between"
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="tw-flex tw-flex-col  tw-h-auto ">
        <div className="tw-flex tw-items-center tw-gap-2">
          <Image
            src={user?.avatar}
            width={50}
            height={50}
            className="tw-rounded-full"
            alt={user?.username}
          />
          <div>
            <div className="tw-text-xl tw-font-bold tw-z-10">
              {greeting}, {user?.username}
            </div>
            <div className="tw-text-sm tw-font-semibold tw-z-10 tw-text-black/40">
              {secondaryGreeting}
            </div>
            <div></div>
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-mt-4 tw-gap-1 tw-w-full md:tw-w-max  tw-bg-gray-100 tw-p-3 tw-rounded-3xl tw-items-center">
          <div className="tw-text-xs tw-w-max    tw-uppercase tw-font-black tw-tracking-widest tw-text-center tw-mt-1 tw-rounded-full">
            {currentStreak} Daily Streak
          </div>
          <div className="tw-text-sm tw-text-center tw-font-semibold tw-text-black/40">
            {5 - getFilledSegments(currentStreak)} day
            {5 - getFilledSegments(currentStreak) === 1 ? "" : "s"} away from a
            <span className="tw-text-purple-700 tw-font-bold">
              {" "}
              free voice pass!
            </span>
          </div>
          <div className="tw-flex tw-items-center tw-gap-1">
            {[...Array(5)].map((_, index) => (
              <div
                key={index}
                className={`tw-size-3 tw-rounded-[4px] ${
                  index < getFilledSegments(currentStreak)
                    ? "tw-bg-purple-600"
                    : "tw-bg-gray-200"
                }`}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-2 tw-mt-auto tw-bg-gray-100 tw-p-3 tw-rounded-3xl tw-items-center">
        <div className="tw-text-xs tw-font-black tw-uppercase tw-tracking-widest tw-text-center">
          QUICK ACTIONS
        </div>
        <SecondaryButton
          as={Link}
          href="/train"
          className="tw-rounded-full tw-border-none tw-h-10 "
        >
          <PiMicrophoneStageFill size={20} />
          Train a Voice
        </SecondaryButton>

        <SecondaryButton
          as={Link}
          href={"https://feedback.jammable.com"}
          className="tw-rounded-full tw-h-10 tw-border-none"
          target="_blank"
        >
          <FaHeart size={20} />
          Give Feedback
        </SecondaryButton>
      </div>

      {/* <div className="tw-flex tw-items-center tw-gap-0.5 tw-flex-col tw-max-w-40">
        <div className="tw-relative tw-w-24 tw-h-24">
          <svg
            viewBox="0 0 36 36"
            className="tw-w-full tw-h-full tw-transform tw--rotate-90"
          >
            {[0, 1, 2, 3, 4].map((index) => (
              <circle
                key={index}
                cx="18"
                cy="18"
                r="15.91549430918954"
                fill="transparent"
                stroke={
                  index < getFilledSegments(currentStreak)
                    ? "#782dc8"
                    : "#e5e7eb"
                }
                strokeWidth="3"
                strokeDasharray="15 85" // Changed from "20 80" to "18 82"
                strokeDashoffset={-20 * index}
                strokeLinecap="round" // Added to make sections rounded
              />
            ))}
          </svg>
          <div className="tw-absolute tw-inset-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-sm tw-font-bold">
            <IoFlame size={40} />
            <span className="tw-absolute tw-bg-white tw-rounded-full tw-px-0.5  tw-text-black tw-bottom-4">
              {currentStreak}
            </span>
          </div>
        </div>
        <div className="tw-text-md tw-font-black tw-text-center tw-mt-1">
          Daily Streak
        </div>
        <div className="tw-text-sm tw-text-center tw-font-semibold tw-text-black/40">
          {5 - getFilledSegments(currentStreak)} day
          {5 - getFilledSegments(currentStreak) === 1 ? "" : "s"} away from a
          <span className="tw-text-purple-700 tw-font-bold">
            {" "}
            free voice pass!
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default ProfileSection;
