import Image from "next/image";
import React from "react";
import { rgbDataURL } from "../utils/tools";

const VoiceImage = ({
  model,
  size,
  className,
  fill,
  sizes,
  quality,
  priority,
  style,
}) => {
  let rgb = model?.image?.blurhash.split(",");

  const defaultRGB = [255, 255, 255]; // default to white color

  const safeRGB = (rgb) => {
    if (!rgb || rgb.length !== 3) {
      return defaultRGB;
    }
    return rgb;
  };

  return (
    <Image
      src={model?.image?.url}
      width={size}
      height={size}
      className={` ${className}`}
      placeholder="blur"
      blurDataURL={rgbDataURL(
        safeRGB(rgb)[0],
        safeRGB(rgb)[1],
        safeRGB(rgb)[2],
      )}
      alt={`${model?.artistname} Image`}
      fill={fill ? true : false}
      sizes={sizes ? sizes : null}
      quality={quality ? quality : 75}
      priority={priority ? priority : false}
      style={style}
    />
  );
};

export default VoiceImage;
