import React from "react";
import { cn } from "@jammable/ui-core";
import ytIcon from "./assets/youtube.svg";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode;
  inputClassName?: string;
}

export const inputStyle =
  "tw-m-0 tw-transition-all tw-font-bold tw-h-12 tw-px-4 tw-py-0 tw-border tw-w-full tw-bg-gray-100 tw-border-gray-100 focus:tw-border-gray-200 tw-border-solid tw-rounded-xl tw-justify-start tw-items-center tw-text-base tw-leading-tight tw-text-black";

export const inputWithIconStyle = "tw-pl-16"; // Add left padding to accommodate the icon

export const iconStyle =
  "tw-absolute  tw-left-0 tw-px-3 tw-pl-4 tw-rounded-l-2xl tw-top-1/2 tw-transform tw--translate-y-1/2 tw-flex tw-items-center tw-bg-gray-200/40 tw-justify-center tw-h-full";

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, icon, inputClassName, ...props }, ref) => {
    return (
      <div className="tw-relative tw-w-full">
        {icon && <div className={cn(iconStyle, inputClassName)}>{icon}</div>}
        <input
          type={type}
          className={cn(inputStyle, icon && inputWithIconStyle, className)}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);

Input.displayName = "Input";

export default Input;

export const YoutubeInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        style={{ backgroundImage: `url(${ytIcon.src || ytIcon})` }}
        className={cn(
          inputStyle,
          "tw-bg-no-repeat tw-pl-10 tw-text-sm [background-position:_10px]",
          className,
        )}
        ref={ref}
        placeholder="Paste a YouTube link"
        {...props}
      />
    );
  },
);

YoutubeInput.displayName = "YoutubeInput";
