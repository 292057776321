import React from "react";
import { cn } from "@jammable/ui-core";

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

export const textAreaStyle =
  "tw-m-0 tw-inset-0 tw-font-bold tw-px-4 tw-py-2 tw-border-0 tw-resize-none tw-bg-neutral-100 tw-border-neutral-100 tw-rounded-xl tw-justify-start tw-items-center tw-text-base tw-leading-tight tw-text-black ";

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        rows={4}
        className={cn(textAreaStyle, className)}
        ref={ref}
        {...props}
      />
    );
  },
);

TextArea.displayName = "TextArea";
