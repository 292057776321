import Image from "next/image";
import Link from "next/link";
import React from "react";
import { FaUser } from "react-icons/fa";

const ProfileBadge = ({ username, avatar, className }) => {
  return (
    <Link
      href={`/@/${username}`}
      className={`tw-text-lg tw-font-bold tw-overflow-visible tw-flex tw-items-center tw-gap-2 tw-text-sm ${className}`}
    >
      <span
        className={` tw-bg-gray-200/60 hover:tw-bg-gray-200 tw-transition-all  tw-flex tw-items-center tw-gap-1 tw-w-max tw-text-sm  tw-p-1 tw-px-2 tw-rounded-full ${
          avatar ? "tw-pl-1 tw-text-black " : "tw-text-purple-700"
        }`}
      >
        {avatar ? (
          <img
            src={avatar}
            width={24}
            height={24}
            className="tw-inline-block tw-rounded-full"
            alt={username}
          />
        ) : (
          <FaUser size={14} />
        )}
        {username}
      </span>{" "}
    </Link>
  );
};

export default ProfileBadge;
